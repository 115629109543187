@import "./fonts/index.scss";
@import "./themes/main.scss";
@import "./grid-system.scss";
@import "./buttons.scss";

* {
    margin: 0;
    box-sizing: border-box;
    font-family: var(--font-family-pr);
    -webkit-font-smoothing: antialiased;
}
body {
    overflow-x: hidden;
}

.tooltip {
    position: absolute;
    width: max-content;
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 5px;
    color: white;
    z-index: 9;

    &.top {
        bottom: calc(100% + 14px);
        left: 50%;
        transform: translateX(-50%);

        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            margin: auto;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid rgba(0, 0, 0, 0.9);
        }
    }
    &.bottom {
        top: calc(100% + 14px);
        left: 50%;
        transform: translateX(-50%);

        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 100%;
            margin: auto;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid rgba(0, 0, 0, 0.9);
        }
    }
    &.left {
        top: 50%;
        transform: translateY(-50%);
        right: calc(100% + 14px);

        &::before {
            content: "";
            position: absolute;
            left: 100%;
            bottom: 0;
            top: 0;
            margin: auto;
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid rgba(0, 0, 0, 0.9);
        }
    }
    &.right {
        top: 50%;
        transform: translateY(-50%);
        left: calc(100% + 14px);

        &::before {
            content: "";
            position: absolute;
            right: 100%;
            bottom: 0;
            top: 0;
            margin: auto;
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-right: 8px solid rgba(0, 0, 0, 0.9);
        }
    }
    &.show {
        opacity: 1;
    }
    &:not(.show) {
        opacity: 0;
    }
}
$numbers: 0, 1, 2, 3, 4, 5;
.padding {
    @each $n in $numbers {
        &-#{$n} {
            padding: #{$n}em !important;
        }
    }

    &-b {
        @each $n in $numbers {
            &-#{$n} {
                padding-bottom: #{$n}em !important;
            }
        }
    }
    &-t {
        @each $n in $numbers {
            &-#{$n} {
                padding-top: #{$n}em !important;
            }
        }
    }
    &-l {
        @each $n in $numbers {
            &-#{$n} {
                padding-left: #{$n}em !important;
            }
        }
    }
    &-r {
        @each $n in $numbers {
            &-#{$n} {
                padding-right: #{$n}em !important;
            }
        }
    }
    &-h {
        @each $n in $numbers {
            &-#{$n} {
                padding-left: #{$n}em !important;
                padding-right: #{$n}em !important;
            }
        }
    }
    &-v {
        @each $n in $numbers {
            &-#{$n} {
                padding-top: #{$n}em !important;
                padding-bottom: #{$n}em !important;
            }
        }
    }
}
.margin {
    @each $n in $numbers {
        &-#{$n} {
            margin: #{$n}em !important;
        }
    }

    &-b {
        @each $n in $numbers {
            &-#{$n} {
                margin-bottom: #{$n}em !important;
            }
        }
    }
    &-t {
        @each $n in $numbers {
            &-#{$n} {
                margin-top: #{$n}em !important;
            }
        }
    }
    &-l {
        @each $n in $numbers {
            &-#{$n} {
                margin-left: #{$n}em !important;
            }
        }
    }
    &-r {
        @each $n in $numbers {
            &-#{$n} {
                margin-right: #{$n}em !important;
            }
        }
    }
    &-h {
        @each $n in $numbers {
            &-#{$n} {
                margin-left: #{$n}em !important;
                margin-right: #{$n}em !important;
            }
        }
    }
    &-v {
        @each $n in $numbers {
            &-#{$n} {
                margin-top: #{$n}em !important;
                margin-bottom: #{$n}em !important;
            }
        }
    }
}

.divider {
    width: 100%;
    height: 1px;
    background-color: var(--border);
    margin: 44px 0;
}

blockquote {
    margin: 16px 0;
    border-radius: 3px;
    padding: 1rem;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.5;
    color: #22262a;

    &.success {
        border-left: 5px solid #0ac266;
        background-color: #effbf5;

        & b {
            font-weight: 600;
            color: var(--bg-pr);
        }

        & strong {
            font-weight: 500;
            color: #09aa59;
        }
    }
}

.text-center {
    width: 100%;
    text-align: center;
}

.title {
    width: 100%;
    font-size: 24px;
    line-height: 1.25;
    font-family: var(--font-family-pr);
    color: #002162;
    font-weight: 700;
    margin-bottom: 25px;
    text-align: left !important;
    position: relative;

    &::after {
        content: "";
        width: 85px;
        background-color: var(--active);
        font-weight: 600;
        height: 5px;
        display: block;
        margin: 10px 0 25px;
    }
}
.subtitle {
    font-size: 16px;
    color: var(--active);
    letter-spacing: 1.5px;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    margin: 35px 0 20px;
}
strong {
    color: var(--bg-pr);
}
li {
    list-style-type: disc;
    font-size: 20px;
    color: var(--active);

    & p {
        margin-bottom: 8px;
        padding: 0;
    }
}
.content-video {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;

    & .content-embed-video {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        border: none;
    }
}
p {
    color: var(--text);
    font-size: 17px;
    line-height: 27px;
    margin-bottom: 8px;
}
.loading-full-screen {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
