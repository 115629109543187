.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    min-height: 40px;
    padding: 0 10px;
    border: 1px solid transparent;
    outline: none;
    background-color: var(--bg-pr);
    color: var(--ct-pr);
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    border-radius: 2px;
    box-shadow: var(--shadow);
    text-decoration: none;
    cursor: pointer;

    &.si-mg {
        margin: 10px;
    }

    &.lg {
        min-height: 50px;
        font-size: 20px;
        padding: 0 60px;
    }

    &.full-width {
        width: 100%;
        padding: 0;
    }

    &.text {
        background-color: transparent;
        font-weight: bold;
        color: var(--ct-main);
        box-shadow: none;
    }

    &.outline {
        background-color: transparent;
        font-weight: bold;
        color: var(--bg-pr);
        border-color: var(--bg-pr);
        box-shadow: none;
    }

    & .leading {
        margin-right: 6px;
    }

    & .trading {
        margin-left: 6px;
    }
    &:disabled,
    &.disabled {
        background-color: var(--bg-disabled) !important;
        color: var(--ct-disabled) !important;
    }
}
@media (max-width: 320px) {
    .btn {
        &.lg {
            padding: 0 10px;
        }
    }
}
