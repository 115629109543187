/*Roboto*/
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./Roboto/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./Roboto/Roboto-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(./Roboto/Roboto-Black.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(./Roboto/Roboto-BlackItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./Roboto/Roboto-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./Roboto/Roboto-BoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./Roboto/Roboto-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(./Roboto/Roboto-LightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./Roboto/Roboto-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(./Roboto/Roboto-MediumItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(./Roboto/Roboto-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(./Roboto/Roboto-ThinItalic.ttf) format("truetype");
}
/*Roboto*/
/*Open Sans*/
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./OpenSans/OpenSans-Light.ttf);
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(./OpenSans/OpenSans-LightItalic.ttf);
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./OpenSans/OpenSans-Regular.ttf);
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./OpenSans/OpenSans-Regular.ttf);
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./OpenSans/OpenSans-SemiBold.ttf);
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(./OpenSans/OpenSans-SemiBoldItalic.ttf);
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./OpenSans/OpenSans-Bold.ttf);
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./OpenSans/OpenSans-BoldItalic.ttf);
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(./OpenSans/OpenSans-ExtraBold.ttf);
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(./OpenSans/OpenSans-ExtraBoldItalic.ttf);
}
/*Open Sans*/
:root,
.main {
  --font-family-pr: "Roboto", sans-serif;
  --font-family-se: "Open Sans", sans-serif;
  --shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --overlay: rgb(0, 33, 98, 0.7);
  --border: #eeeeee;
  --transition: 350ms;
  --bg-main: #ffffff;
  --ct-main: #525252;
  --bg-back: #eff4f8;
  --ct-back: #002162;
  --bg-pr: #002162;
  --ct-pr: #ffffff;
  --title: #002162;
  --subtitle: #878c92;
  --text: #525252;
  --active: #0058c0;
  --success: ;
  --danger: ;
  --warning: ;
  --bg-disabled: #dddddd;
  --ct-disabled: #999999;
}

:root {
  --w12: 100%;
  --w11: 91.66%;
  --w10: 83.33%;
  --w9: 75%;
  --w8: 66.66%;
  --w7: 58.33%;
  --w6: 50%;
  --w5: 41.66%;
  --w4: 33.33%;
  --w3: 25%;
  --w2: 16.66%;
  --w1: 8.33%;
}

.row {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-self-flex-start {
  align-self: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.align-self-flex-end {
  align-self: flex-end;
}

.align-items-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.align-items-baseline {
  align-items: baseline;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-auto {
  align-self: auto;
}

.col,
[class*=col-] {
  position: relative;
  box-sizing: border-box;
  -webkit-box-flex: 0;
  padding: 0 16px;
}

.col {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  width: auto;
  max-width: 100%;
}

.col-12 {
  -webkit-flex: 0 0 var(--w12);
  -ms-flex: 0 0 var(--w12);
  flex: 0 0 var(--w12);
  max-width: var(--w12);
}

.col-11 {
  -webkit-flex: 0 0 var(--w11);
  -ms-flex: 0 0 var(--w11);
  flex: 0 0 var(--w11);
  max-width: var(--w11);
}

.col-10 {
  -webkit-flex: 0 0 var(--w10);
  -ms-flex: 0 0 var(--w10);
  flex: 0 0 var(--w10);
  max-width: var(--w10);
}

.col-9 {
  -webkit-flex: 0 0 var(--w9);
  -ms-flex: 0 0 var(--w9);
  flex: 0 0 var(--w9);
  max-width: var(--w9);
}

.col-8 {
  -webkit-flex: 0 0 var(--w8);
  -ms-flex: 0 0 var(--w8);
  flex: 0 0 var(--w8);
  max-width: var(--w8);
}

.col-7 {
  -webkit-flex: 0 0 var(--w7);
  -ms-flex: 0 0 var(--w7);
  flex: 0 0 var(--w7);
  max-width: var(--w7);
}

.col-6 {
  -webkit-flex: 0 0 var(--w6);
  -ms-flex: 0 0 var(--w6);
  flex: 0 0 var(--w6);
  max-width: var(--w6);
}

.col-5 {
  -webkit-flex: 0 0 var(--w5);
  -ms-flex: 0 0 var(--w5);
  flex: 0 0 var(--w5);
  max-width: var(--w5);
}

.col-4 {
  -webkit-flex: 0 0 var(--w4);
  -ms-flex: 0 0 var(--w4);
  flex: 0 0 var(--w4);
  max-width: var(--w4);
}

.col-3 {
  -webkit-flex: 0 0 var(--w3);
  -ms-flex: 0 0 var(--w3);
  flex: 0 0 var(--w3);
  max-width: var(--w3);
}

.col-2 {
  -webkit-flex: 0 0 var(--w2);
  -ms-flex: 0 0 var(--w2);
  flex: 0 0 var(--w2);
  max-width: var(--w2);
}

.col-1 {
  -webkit-flex: 0 0 var(--w1);
  -ms-flex: 0 0 var(--w1);
  flex: 0 0 var(--w1);
  max-width: var(--w1);
}

/*Ultra Small*/
@media (max-width: 575px) {
  .justify-content-xs-center {
    justify-content: center;
  }

  .justify-content-xs-flex-start {
    justify-content: flex-start;
  }

  .justify-content-xs-flex-end {
    justify-content: flex-end;
  }

  .justify-content-xs-space-between {
    justify-content: space-between;
  }

  .justify-content-xs-space-around {
    justify-content: space-around;
  }

  .justify-content-xs-space-evenly {
    justify-content: space-evenly;
  }

  .align-items-xs-center {
    align-items: center;
  }

  .align-self-xs-center {
    align-self: center;
  }

  .align-items-xs-flex-start {
    align-items: flex-start;
  }

  .align-self-xs-flex-start {
    align-self: flex-start;
  }

  .align-items-xs-flex-end {
    align-items: flex-end;
  }

  .align-self-xs-flex-end {
    align-self: flex-end;
  }

  .align-items-xs-stretch {
    align-items: stretch;
  }

  .align-self-xs-stretch {
    align-self: stretch;
  }

  .align-items-xs-baseline {
    align-items: baseline;
  }

  .align-self-xs-baseline {
    align-self: baseline;
  }

  .align-self-xs-auto {
    align-self: auto;
  }

  .xs {
    -webkit-flex: 0 0 var(--w12);
    -ms-flex: 0 0 var(--w12);
    flex: 0 0 var(--w12);
    max-width: var(--w12);
  }

  .pull-xs {
    right: var(--w12);
  }

  .push-xs {
    left: var(--w12);
  }

  .offset-xs {
    margin-left: var(--w12);
  }

  .xs-12 {
    -webkit-flex: 0 0 var(--w12);
    -ms-flex: 0 0 var(--w12);
    flex: 0 0 var(--w12);
    max-width: var(--w12);
  }

  .pull-xs-12 {
    right: var(--w12);
  }

  .push-xs-12 {
    left: var(--w12);
  }

  .offset-xs-12 {
    margin-left: var(--w12);
  }

  .xs-11 {
    -webkit-flex: 0 0 var(--w11);
    -ms-flex: 0 0 var(--w11);
    flex: 0 0 var(--w11);
    max-width: var(--w11);
  }

  .pull-xs-11 {
    right: var(--w11);
  }

  .push-xs-11 {
    left: var(--w11);
  }

  .offset-xs-11 {
    margin-left: var(--w11);
  }

  .xs-10 {
    -webkit-flex: 0 0 var(--w10);
    -ms-flex: 0 0 var(--w10);
    flex: 0 0 var(--w10);
    max-width: var(--w10);
  }

  .pull-xs-10 {
    right: var(--w10);
  }

  .push-xs-10 {
    left: var(--w10);
  }

  .offset-xs-10 {
    margin-left: var(--w10);
  }

  .xs-9 {
    -webkit-flex: 0 0 var(--w9);
    -ms-flex: 0 0 var(--w9);
    flex: 0 0 var(--w9);
    max-width: var(--w9);
  }

  .pull-xs-9 {
    right: var(--w9);
  }

  .push-xs-9 {
    left: var(--w9);
  }

  .offset-xs-9 {
    margin-left: var(--w9);
  }

  .xs-8 {
    -webkit-flex: 0 0 var(--w8);
    -ms-flex: 0 0 var(--w8);
    flex: 0 0 var(--w8);
    max-width: var(--w8);
  }

  .pull-xs-8 {
    right: var(--w8);
  }

  .push-xs-8 {
    left: var(--w8);
  }

  .offset-xs-8 {
    margin-left: var(--w8);
  }

  .xs-7 {
    -webkit-flex: 0 0 var(--w7);
    -ms-flex: 0 0 var(--w7);
    flex: 0 0 var(--w7);
    max-width: var(--w7);
  }

  .pull-xs-7 {
    right: var(--w7);
  }

  .push-xs-7 {
    left: var(--w7);
  }

  .offset-xs-7 {
    margin-left: var(--w7);
  }

  .xs-6 {
    -webkit-flex: 0 0 var(--w6);
    -ms-flex: 0 0 var(--w6);
    flex: 0 0 var(--w6);
    max-width: var(--w6);
  }

  .pull-xs-6 {
    right: var(--w6);
  }

  .push-xs-6 {
    left: var(--w6);
  }

  .offset-xs-6 {
    margin-left: var(--w6);
  }

  .xs-5 {
    -webkit-flex: 0 0 var(--w5);
    -ms-flex: 0 0 var(--w5);
    flex: 0 0 var(--w5);
    max-width: var(--w5);
  }

  .pull-xs-5 {
    right: var(--w5);
  }

  .push-xs-5 {
    left: var(--w5);
  }

  .offset-xs-5 {
    margin-left: var(--w5);
  }

  .xs-4 {
    -webkit-flex: 0 0 var(--w4);
    -ms-flex: 0 0 var(--w4);
    flex: 0 0 var(--w4);
    max-width: var(--w4);
  }

  .pull-xs-4 {
    right: var(--w4);
  }

  .push-xs-4 {
    left: var(--w4);
  }

  .offset-xs-4 {
    margin-left: var(--w4);
  }

  .xs-3 {
    -webkit-flex: 0 0 var(--w3);
    -ms-flex: 0 0 var(--w3);
    flex: 0 0 var(--w3);
    max-width: var(--w3);
  }

  .pull-xs-3 {
    right: var(--w3);
  }

  .push-xs-3 {
    left: var(--w3);
  }

  .offset-xs-3 {
    margin-left: var(--w3);
  }

  .xs-2 {
    -webkit-flex: 0 0 var(--w2);
    -ms-flex: 0 0 var(--w2);
    flex: 0 0 var(--w2);
    max-width: var(--w2);
  }

  .pull-xs-2 {
    right: var(--w2);
  }

  .push-xs-2 {
    left: var(--w2);
  }

  .offset-xs-2 {
    margin-left: var(--w2);
  }

  .xs-1 {
    -webkit-flex: 0 0 var(--w1);
    -ms-flex: 0 0 var(--w1);
    flex: 0 0 var(--w1);
    max-width: var(--w1);
  }

  .pull-xs-1 {
    right: var(--w1);
  }

  .push-xs-1 {
    left: var(--w1);
  }

  .offset-xs-1 {
    margin-left: var(--w1);
  }
}
/*Ultra Small*/
/*Small*/
@media (min-width: 576px) {
  .justify-content-sm-center {
    justify-content: center;
  }

  .justify-content-sm-flex-start {
    justify-content: flex-start;
  }

  .justify-content-sm-flex-end {
    justify-content: flex-end;
  }

  .justify-content-sm-space-between {
    justify-content: space-between;
  }

  .justify-content-sm-space-around {
    justify-content: space-around;
  }

  .justify-content-sm-space-evenly {
    justify-content: space-evenly;
  }

  .align-items-sm-center {
    align-items: center;
  }

  .align-self-sm-center {
    align-self: center;
  }

  .align-items-sm-flex-start {
    align-items: flex-start;
  }

  .align-self-sm-flex-start {
    align-self: flex-start;
  }

  .align-items-sm-flex-end {
    align-items: flex-end;
  }

  .align-self-sm-flex-end {
    align-self: flex-end;
  }

  .align-items-sm-stretch {
    align-items: stretch;
  }

  .align-self-sm-stretch {
    align-self: stretch;
  }

  .align-items-sm-baseline {
    align-items: baseline;
  }

  .align-self-sm-baseline {
    align-self: baseline;
  }

  .align-self-sm-auto {
    align-self: auto;
  }

  .sm {
    -webkit-flex: 0 0 var(--w12);
    -ms-flex: 0 0 var(--w12);
    flex: 0 0 var(--w12);
    max-width: var(--w12);
  }

  .pull-sm {
    right: var(--w12);
  }

  .push-sm {
    left: var(--w12);
  }

  .offset-sm {
    margin-left: var(--w12);
  }

  .sm-12 {
    -webkit-flex: 0 0 var(--w12);
    -ms-flex: 0 0 var(--w12);
    flex: 0 0 var(--w12);
    max-width: var(--w12);
  }

  .pull-sm-12 {
    right: var(--w12);
  }

  .push-sm-12 {
    left: var(--w12);
  }

  .offset-sm-12 {
    margin-left: var(--w12);
  }

  .sm-11 {
    -webkit-flex: 0 0 var(--w11);
    -ms-flex: 0 0 var(--w11);
    flex: 0 0 var(--w11);
    max-width: var(--w11);
  }

  .pull-sm-11 {
    right: var(--w11);
  }

  .push-sm-11 {
    left: var(--w11);
  }

  .offset-sm-11 {
    margin-left: var(--w11);
  }

  .sm-10 {
    -webkit-flex: 0 0 var(--w10);
    -ms-flex: 0 0 var(--w10);
    flex: 0 0 var(--w10);
    max-width: var(--w10);
  }

  .pull-sm-10 {
    right: var(--w10);
  }

  .push-sm-10 {
    left: var(--w10);
  }

  .offset-sm-10 {
    margin-left: var(--w10);
  }

  .sm-9 {
    -webkit-flex: 0 0 var(--w9);
    -ms-flex: 0 0 var(--w9);
    flex: 0 0 var(--w9);
    max-width: var(--w9);
  }

  .pull-sm-9 {
    right: var(--w9);
  }

  .push-sm-9 {
    left: var(--w9);
  }

  .offset-sm-9 {
    margin-left: var(--w9);
  }

  .sm-8 {
    -webkit-flex: 0 0 var(--w8);
    -ms-flex: 0 0 var(--w8);
    flex: 0 0 var(--w8);
    max-width: var(--w8);
  }

  .pull-sm-8 {
    right: var(--w8);
  }

  .push-sm-8 {
    left: var(--w8);
  }

  .offset-sm-8 {
    margin-left: var(--w8);
  }

  .sm-7 {
    -webkit-flex: 0 0 var(--w7);
    -ms-flex: 0 0 var(--w7);
    flex: 0 0 var(--w7);
    max-width: var(--w7);
  }

  .pull-sm-7 {
    right: var(--w7);
  }

  .push-sm-7 {
    left: var(--w7);
  }

  .offset-sm-7 {
    margin-left: var(--w7);
  }

  .sm-6 {
    -webkit-flex: 0 0 var(--w6);
    -ms-flex: 0 0 var(--w6);
    flex: 0 0 var(--w6);
    max-width: var(--w6);
  }

  .pull-sm-6 {
    right: var(--w6);
  }

  .push-sm-6 {
    left: var(--w6);
  }

  .offset-sm-6 {
    margin-left: var(--w6);
  }

  .sm-5 {
    -webkit-flex: 0 0 var(--w5);
    -ms-flex: 0 0 var(--w5);
    flex: 0 0 var(--w5);
    max-width: var(--w5);
  }

  .pull-sm-5 {
    right: var(--w5);
  }

  .push-sm-5 {
    left: var(--w5);
  }

  .offset-sm-5 {
    margin-left: var(--w5);
  }

  .sm-4 {
    -webkit-flex: 0 0 var(--w4);
    -ms-flex: 0 0 var(--w4);
    flex: 0 0 var(--w4);
    max-width: var(--w4);
  }

  .pull-sm-4 {
    right: var(--w4);
  }

  .push-sm-4 {
    left: var(--w4);
  }

  .offset-sm-4 {
    margin-left: var(--w4);
  }

  .sm-3 {
    -webkit-flex: 0 0 var(--w3);
    -ms-flex: 0 0 var(--w3);
    flex: 0 0 var(--w3);
    max-width: var(--w3);
  }

  .pull-sm-3 {
    right: var(--w3);
  }

  .push-sm-3 {
    left: var(--w3);
  }

  .offset-sm-3 {
    margin-left: var(--w3);
  }

  .sm-2 {
    -webkit-flex: 0 0 var(--w2);
    -ms-flex: 0 0 var(--w2);
    flex: 0 0 var(--w2);
    max-width: var(--w2);
  }

  .pull-sm-2 {
    right: var(--w2);
  }

  .push-sm-2 {
    left: var(--w2);
  }

  .offset-sm-2 {
    margin-left: var(--w2);
  }

  .sm-1 {
    -webkit-flex: 0 0 var(--w1);
    -ms-flex: 0 0 var(--w1);
    flex: 0 0 var(--w1);
    max-width: var(--w1);
  }

  .pull-sm-1 {
    right: var(--w1);
  }

  .push-sm-1 {
    left: var(--w1);
  }

  .offset-sm-1 {
    margin-left: var(--w1);
  }
}
/*Small*/
/*Medium*/
@media (min-width: 768px) {
  .justify-content-md-center {
    justify-content: center;
  }

  .justify-content-md-flex-start {
    justify-content: flex-start;
  }

  .justify-content-md-flex-end {
    justify-content: flex-end;
  }

  .justify-content-md-space-between {
    justify-content: space-between;
  }

  .justify-content-md-space-around {
    justify-content: space-around;
  }

  .justify-content-md-space-evenly {
    justify-content: space-evenly;
  }

  .align-items-md-center {
    align-items: center;
  }

  .align-self-md-center {
    align-self: center;
  }

  .align-items-md-flex-start {
    align-items: flex-start;
  }

  .align-self-md-flex-start {
    align-self: flex-start;
  }

  .align-items-md-flex-end {
    align-items: flex-end;
  }

  .align-self-md-flex-end {
    align-self: flex-end;
  }

  .align-items-md-stretch {
    align-items: stretch;
  }

  .align-self-md-stretch {
    align-self: stretch;
  }

  .align-items-md-baseline {
    align-items: baseline;
  }

  .align-self-md-baseline {
    align-self: baseline;
  }

  .align-self-md-auto {
    align-self: auto;
  }

  .md {
    -webkit-flex: 0 0 var(--w12);
    -ms-flex: 0 0 var(--w12);
    flex: 0 0 var(--w12);
    max-width: var(--w12);
  }

  .pull-md {
    right: var(--w12);
  }

  .push-md {
    left: var(--w12);
  }

  .offset-md {
    margin-left: var(--w12);
  }

  .md-12 {
    -webkit-flex: 0 0 var(--w12);
    -ms-flex: 0 0 var(--w12);
    flex: 0 0 var(--w12);
    max-width: var(--w12);
  }

  .pull-md-12 {
    right: var(--w12);
  }

  .push-md-12 {
    left: var(--w12);
  }

  .offset-md-12 {
    margin-left: var(--w12);
  }

  .md-11 {
    -webkit-flex: 0 0 var(--w11);
    -ms-flex: 0 0 var(--w11);
    flex: 0 0 var(--w11);
    max-width: var(--w11);
  }

  .pull-md-11 {
    right: var(--w11);
  }

  .push-md-11 {
    left: var(--w11);
  }

  .offset-md-11 {
    margin-left: var(--w11);
  }

  .md-10 {
    -webkit-flex: 0 0 var(--w10);
    -ms-flex: 0 0 var(--w10);
    flex: 0 0 var(--w10);
    max-width: var(--w10);
  }

  .pull-md-10 {
    right: var(--w10);
  }

  .push-md-10 {
    left: var(--w10);
  }

  .offset-md-10 {
    margin-left: var(--w10);
  }

  .md-9 {
    -webkit-flex: 0 0 var(--w9);
    -ms-flex: 0 0 var(--w9);
    flex: 0 0 var(--w9);
    max-width: var(--w9);
  }

  .pull-md-9 {
    right: var(--w9);
  }

  .push-md-9 {
    left: var(--w9);
  }

  .offset-md-9 {
    margin-left: var(--w9);
  }

  .md-8 {
    -webkit-flex: 0 0 var(--w8);
    -ms-flex: 0 0 var(--w8);
    flex: 0 0 var(--w8);
    max-width: var(--w8);
  }

  .pull-md-8 {
    right: var(--w8);
  }

  .push-md-8 {
    left: var(--w8);
  }

  .offset-md-8 {
    margin-left: var(--w8);
  }

  .md-7 {
    -webkit-flex: 0 0 var(--w7);
    -ms-flex: 0 0 var(--w7);
    flex: 0 0 var(--w7);
    max-width: var(--w7);
  }

  .pull-md-7 {
    right: var(--w7);
  }

  .push-md-7 {
    left: var(--w7);
  }

  .offset-md-7 {
    margin-left: var(--w7);
  }

  .md-6 {
    -webkit-flex: 0 0 var(--w6);
    -ms-flex: 0 0 var(--w6);
    flex: 0 0 var(--w6);
    max-width: var(--w6);
  }

  .pull-md-6 {
    right: var(--w6);
  }

  .push-md-6 {
    left: var(--w6);
  }

  .offset-md-6 {
    margin-left: var(--w6);
  }

  .md-5 {
    -webkit-flex: 0 0 var(--w5);
    -ms-flex: 0 0 var(--w5);
    flex: 0 0 var(--w5);
    max-width: var(--w5);
  }

  .pull-md-5 {
    right: var(--w5);
  }

  .push-md-5 {
    left: var(--w5);
  }

  .offset-md-5 {
    margin-left: var(--w5);
  }

  .md-4 {
    -webkit-flex: 0 0 var(--w4);
    -ms-flex: 0 0 var(--w4);
    flex: 0 0 var(--w4);
    max-width: var(--w4);
  }

  .pull-md-4 {
    right: var(--w4);
  }

  .push-md-4 {
    left: var(--w4);
  }

  .offset-md-4 {
    margin-left: var(--w4);
  }

  .md-3 {
    -webkit-flex: 0 0 var(--w3);
    -ms-flex: 0 0 var(--w3);
    flex: 0 0 var(--w3);
    max-width: var(--w3);
  }

  .pull-md-3 {
    right: var(--w3);
  }

  .push-md-3 {
    left: var(--w3);
  }

  .offset-md-3 {
    margin-left: var(--w3);
  }

  .md-2 {
    -webkit-flex: 0 0 var(--w2);
    -ms-flex: 0 0 var(--w2);
    flex: 0 0 var(--w2);
    max-width: var(--w2);
  }

  .pull-md-2 {
    right: var(--w2);
  }

  .push-md-2 {
    left: var(--w2);
  }

  .offset-md-2 {
    margin-left: var(--w2);
  }

  .md-1 {
    -webkit-flex: 0 0 var(--w1);
    -ms-flex: 0 0 var(--w1);
    flex: 0 0 var(--w1);
    max-width: var(--w1);
  }

  .pull-md-1 {
    right: var(--w1);
  }

  .push-md-1 {
    left: var(--w1);
  }

  .offset-md-1 {
    margin-left: var(--w1);
  }
}
/*Medium*/
/*Large*/
@media (min-width: 992px) {
  .justify-content-lg-center {
    justify-content: center;
  }

  .justify-content-lg-flex-start {
    justify-content: flex-start;
  }

  .justify-content-lg-flex-end {
    justify-content: flex-end;
  }

  .justify-content-lg-space-between {
    justify-content: space-between;
  }

  .justify-content-lg-space-around {
    justify-content: space-around;
  }

  .justify-content-lg-space-evenly {
    justify-content: space-evenly;
  }

  .align-items-lg-center {
    align-items: center;
  }

  .align-self-lg-center {
    align-self: center;
  }

  .align-items-lg-flex-start {
    align-items: flex-start;
  }

  .align-self-lg-flex-start {
    align-self: flex-start;
  }

  .align-items-lg-flex-end {
    align-items: flex-end;
  }

  .align-self-lg-flex-end {
    align-self: flex-end;
  }

  .align-items-lg-stretch {
    align-items: stretch;
  }

  .align-self-lg-stretch {
    align-self: stretch;
  }

  .align-items-lg-baseline {
    align-items: baseline;
  }

  .align-self-lg-baseline {
    align-self: baseline;
  }

  .align-self-lg-auto {
    align-self: auto;
  }

  .lg {
    -webkit-flex: 0 0 var(--w12);
    -ms-flex: 0 0 var(--w12);
    flex: 0 0 var(--w12);
    max-width: var(--w12);
  }

  .pull-lg {
    right: var(--w12);
  }

  .push-lg {
    left: var(--w12);
  }

  .offset-lg {
    margin-left: var(--w12);
  }

  .lg-12 {
    -webkit-flex: 0 0 var(--w12);
    -ms-flex: 0 0 var(--w12);
    flex: 0 0 var(--w12);
    max-width: var(--w12);
  }

  .pull-lg-12 {
    right: var(--w12);
  }

  .push-lg-12 {
    left: var(--w12);
  }

  .offset-lg-12 {
    margin-left: var(--w12);
  }

  .lg-11 {
    -webkit-flex: 0 0 var(--w11);
    -ms-flex: 0 0 var(--w11);
    flex: 0 0 var(--w11);
    max-width: var(--w11);
  }

  .pull-lg-11 {
    right: var(--w11);
  }

  .push-lg-11 {
    left: var(--w11);
  }

  .offset-lg-11 {
    margin-left: var(--w11);
  }

  .lg-10 {
    -webkit-flex: 0 0 var(--w10);
    -ms-flex: 0 0 var(--w10);
    flex: 0 0 var(--w10);
    max-width: var(--w10);
  }

  .pull-lg-10 {
    right: var(--w10);
  }

  .push-lg-10 {
    left: var(--w10);
  }

  .offset-lg-10 {
    margin-left: var(--w10);
  }

  .lg-9 {
    -webkit-flex: 0 0 var(--w9);
    -ms-flex: 0 0 var(--w9);
    flex: 0 0 var(--w9);
    max-width: var(--w9);
  }

  .pull-lg-9 {
    right: var(--w9);
  }

  .push-lg-9 {
    left: var(--w9);
  }

  .offset-lg-9 {
    margin-left: var(--w9);
  }

  .lg-8 {
    -webkit-flex: 0 0 var(--w8);
    -ms-flex: 0 0 var(--w8);
    flex: 0 0 var(--w8);
    max-width: var(--w8);
  }

  .pull-lg-8 {
    right: var(--w8);
  }

  .push-lg-8 {
    left: var(--w8);
  }

  .offset-lg-8 {
    margin-left: var(--w8);
  }

  .lg-7 {
    -webkit-flex: 0 0 var(--w7);
    -ms-flex: 0 0 var(--w7);
    flex: 0 0 var(--w7);
    max-width: var(--w7);
  }

  .pull-lg-7 {
    right: var(--w7);
  }

  .push-lg-7 {
    left: var(--w7);
  }

  .offset-lg-7 {
    margin-left: var(--w7);
  }

  .lg-6 {
    -webkit-flex: 0 0 var(--w6);
    -ms-flex: 0 0 var(--w6);
    flex: 0 0 var(--w6);
    max-width: var(--w6);
  }

  .pull-lg-6 {
    right: var(--w6);
  }

  .push-lg-6 {
    left: var(--w6);
  }

  .offset-lg-6 {
    margin-left: var(--w6);
  }

  .lg-5 {
    -webkit-flex: 0 0 var(--w5);
    -ms-flex: 0 0 var(--w5);
    flex: 0 0 var(--w5);
    max-width: var(--w5);
  }

  .pull-lg-5 {
    right: var(--w5);
  }

  .push-lg-5 {
    left: var(--w5);
  }

  .offset-lg-5 {
    margin-left: var(--w5);
  }

  .lg-4 {
    -webkit-flex: 0 0 var(--w4);
    -ms-flex: 0 0 var(--w4);
    flex: 0 0 var(--w4);
    max-width: var(--w4);
  }

  .pull-lg-4 {
    right: var(--w4);
  }

  .push-lg-4 {
    left: var(--w4);
  }

  .offset-lg-4 {
    margin-left: var(--w4);
  }

  .lg-3 {
    -webkit-flex: 0 0 var(--w3);
    -ms-flex: 0 0 var(--w3);
    flex: 0 0 var(--w3);
    max-width: var(--w3);
  }

  .pull-lg-3 {
    right: var(--w3);
  }

  .push-lg-3 {
    left: var(--w3);
  }

  .offset-lg-3 {
    margin-left: var(--w3);
  }

  .lg-2 {
    -webkit-flex: 0 0 var(--w2);
    -ms-flex: 0 0 var(--w2);
    flex: 0 0 var(--w2);
    max-width: var(--w2);
  }

  .pull-lg-2 {
    right: var(--w2);
  }

  .push-lg-2 {
    left: var(--w2);
  }

  .offset-lg-2 {
    margin-left: var(--w2);
  }

  .lg-1 {
    -webkit-flex: 0 0 var(--w1);
    -ms-flex: 0 0 var(--w1);
    flex: 0 0 var(--w1);
    max-width: var(--w1);
  }

  .pull-lg-1 {
    right: var(--w1);
  }

  .push-lg-1 {
    left: var(--w1);
  }

  .offset-lg-1 {
    margin-left: var(--w1);
  }
}
/*Large*/
/*Ultra Large*/
@media (min-width: 1200px) {
  .justify-content-ul-center {
    justify-content: center;
  }

  .justify-content-ul-flex-start {
    justify-content: flex-start;
  }

  .justify-content-ul-flex-end {
    justify-content: flex-end;
  }

  .justify-content-ul-space-between {
    justify-content: space-between;
  }

  .justify-content-ul-space-around {
    justify-content: space-around;
  }

  .justify-content-ul-space-evenly {
    justify-content: space-evenly;
  }

  .align-items-ul-center {
    align-items: center;
  }

  .align-self-ul-center {
    align-self: center;
  }

  .align-items-ul-flex-start {
    align-items: flex-start;
  }

  .align-self-ul-flex-start {
    align-self: flex-start;
  }

  .align-items-ul-flex-end {
    align-items: flex-end;
  }

  .align-self-ul-flex-end {
    align-self: flex-end;
  }

  .align-items-ul-stretch {
    align-items: stretch;
  }

  .align-self-ul-stretch {
    align-self: stretch;
  }

  .align-items-ul-baseline {
    align-items: baseline;
  }

  .align-self-ul-baseline {
    align-self: baseline;
  }

  .align-self-ul-auto {
    align-self: auto;
  }

  .ul {
    -webkit-flex: 0 0 var(--w12);
    -ms-flex: 0 0 var(--w12);
    flex: 0 0 var(--w12);
    max-width: var(--w12);
  }

  .pull-ul {
    right: var(--w12);
  }

  .push-ul {
    left: var(--w12);
  }

  .offset-ul {
    margin-left: var(--w12);
  }

  .ul-12 {
    -webkit-flex: 0 0 var(--w12);
    -ms-flex: 0 0 var(--w12);
    flex: 0 0 var(--w12);
    max-width: var(--w12);
  }

  .pull-ul-12 {
    right: var(--w12);
  }

  .push-ul-12 {
    left: var(--w12);
  }

  .offset-ul-12 {
    margin-left: var(--w12);
  }

  .ul-11 {
    -webkit-flex: 0 0 var(--w11);
    -ms-flex: 0 0 var(--w11);
    flex: 0 0 var(--w11);
    max-width: var(--w11);
  }

  .pull-ul-11 {
    right: var(--w11);
  }

  .push-ul-11 {
    left: var(--w11);
  }

  .offset-ul-11 {
    margin-left: var(--w11);
  }

  .ul-10 {
    -webkit-flex: 0 0 var(--w10);
    -ms-flex: 0 0 var(--w10);
    flex: 0 0 var(--w10);
    max-width: var(--w10);
  }

  .pull-ul-10 {
    right: var(--w10);
  }

  .push-ul-10 {
    left: var(--w10);
  }

  .offset-ul-10 {
    margin-left: var(--w10);
  }

  .ul-9 {
    -webkit-flex: 0 0 var(--w9);
    -ms-flex: 0 0 var(--w9);
    flex: 0 0 var(--w9);
    max-width: var(--w9);
  }

  .pull-ul-9 {
    right: var(--w9);
  }

  .push-ul-9 {
    left: var(--w9);
  }

  .offset-ul-9 {
    margin-left: var(--w9);
  }

  .ul-8 {
    -webkit-flex: 0 0 var(--w8);
    -ms-flex: 0 0 var(--w8);
    flex: 0 0 var(--w8);
    max-width: var(--w8);
  }

  .pull-ul-8 {
    right: var(--w8);
  }

  .push-ul-8 {
    left: var(--w8);
  }

  .offset-ul-8 {
    margin-left: var(--w8);
  }

  .ul-7 {
    -webkit-flex: 0 0 var(--w7);
    -ms-flex: 0 0 var(--w7);
    flex: 0 0 var(--w7);
    max-width: var(--w7);
  }

  .pull-ul-7 {
    right: var(--w7);
  }

  .push-ul-7 {
    left: var(--w7);
  }

  .offset-ul-7 {
    margin-left: var(--w7);
  }

  .ul-6 {
    -webkit-flex: 0 0 var(--w6);
    -ms-flex: 0 0 var(--w6);
    flex: 0 0 var(--w6);
    max-width: var(--w6);
  }

  .pull-ul-6 {
    right: var(--w6);
  }

  .push-ul-6 {
    left: var(--w6);
  }

  .offset-ul-6 {
    margin-left: var(--w6);
  }

  .ul-5 {
    -webkit-flex: 0 0 var(--w5);
    -ms-flex: 0 0 var(--w5);
    flex: 0 0 var(--w5);
    max-width: var(--w5);
  }

  .pull-ul-5 {
    right: var(--w5);
  }

  .push-ul-5 {
    left: var(--w5);
  }

  .offset-ul-5 {
    margin-left: var(--w5);
  }

  .ul-4 {
    -webkit-flex: 0 0 var(--w4);
    -ms-flex: 0 0 var(--w4);
    flex: 0 0 var(--w4);
    max-width: var(--w4);
  }

  .pull-ul-4 {
    right: var(--w4);
  }

  .push-ul-4 {
    left: var(--w4);
  }

  .offset-ul-4 {
    margin-left: var(--w4);
  }

  .ul-3 {
    -webkit-flex: 0 0 var(--w3);
    -ms-flex: 0 0 var(--w3);
    flex: 0 0 var(--w3);
    max-width: var(--w3);
  }

  .pull-ul-3 {
    right: var(--w3);
  }

  .push-ul-3 {
    left: var(--w3);
  }

  .offset-ul-3 {
    margin-left: var(--w3);
  }

  .ul-2 {
    -webkit-flex: 0 0 var(--w2);
    -ms-flex: 0 0 var(--w2);
    flex: 0 0 var(--w2);
    max-width: var(--w2);
  }

  .pull-ul-2 {
    right: var(--w2);
  }

  .push-ul-2 {
    left: var(--w2);
  }

  .offset-ul-2 {
    margin-left: var(--w2);
  }

  .ul-1 {
    -webkit-flex: 0 0 var(--w1);
    -ms-flex: 0 0 var(--w1);
    flex: 0 0 var(--w1);
    max-width: var(--w1);
  }

  .pull-ul-1 {
    right: var(--w1);
  }

  .push-ul-1 {
    left: var(--w1);
  }

  .offset-ul-1 {
    margin-left: var(--w1);
  }
}
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  min-height: 40px;
  padding: 0 10px;
  border: 1px solid transparent;
  outline: none;
  background-color: var(--bg-pr);
  color: var(--ct-pr);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  border-radius: 2px;
  box-shadow: var(--shadow);
  text-decoration: none;
  cursor: pointer;
}
.btn.si-mg {
  margin: 10px;
}
.btn.lg {
  min-height: 50px;
  font-size: 20px;
  padding: 0 60px;
}
.btn.full-width {
  width: 100%;
  padding: 0;
}
.btn.text {
  background-color: transparent;
  font-weight: bold;
  color: var(--ct-main);
  box-shadow: none;
}
.btn.outline {
  background-color: transparent;
  font-weight: bold;
  color: var(--bg-pr);
  border-color: var(--bg-pr);
  box-shadow: none;
}
.btn .leading {
  margin-right: 6px;
}
.btn .trading {
  margin-left: 6px;
}
.btn:disabled, .btn.disabled {
  background-color: var(--bg-disabled) !important;
  color: var(--ct-disabled) !important;
}

@media (max-width: 320px) {
  .btn.lg {
    padding: 0 10px;
  }
}
* {
  margin: 0;
  box-sizing: border-box;
  font-family: var(--font-family-pr);
  -webkit-font-smoothing: antialiased;
}

body {
  overflow-x: hidden;
}

.tooltip {
  position: absolute;
  width: max-content;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  color: white;
  z-index: 9;
}
.tooltip.top {
  bottom: calc(100% + 14px);
  left: 50%;
  transform: translateX(-50%);
}
.tooltip.top::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: auto;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.9);
}
.tooltip.bottom {
  top: calc(100% + 14px);
  left: 50%;
  transform: translateX(-50%);
}
.tooltip.bottom::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  margin: auto;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid rgba(0, 0, 0, 0.9);
}
.tooltip.left {
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% + 14px);
}
.tooltip.left::before {
  content: "";
  position: absolute;
  left: 100%;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.9);
}
.tooltip.right {
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 14px);
}
.tooltip.right::before {
  content: "";
  position: absolute;
  right: 100%;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid rgba(0, 0, 0, 0.9);
}
.tooltip.show {
  opacity: 1;
}
.tooltip:not(.show) {
  opacity: 0;
}

.padding-0 {
  padding: 0em !important;
}
.padding-1 {
  padding: 1em !important;
}
.padding-2 {
  padding: 2em !important;
}
.padding-3 {
  padding: 3em !important;
}
.padding-4 {
  padding: 4em !important;
}
.padding-5 {
  padding: 5em !important;
}
.padding-b-0 {
  padding-bottom: 0em !important;
}
.padding-b-1 {
  padding-bottom: 1em !important;
}
.padding-b-2 {
  padding-bottom: 2em !important;
}
.padding-b-3 {
  padding-bottom: 3em !important;
}
.padding-b-4 {
  padding-bottom: 4em !important;
}
.padding-b-5 {
  padding-bottom: 5em !important;
}
.padding-t-0 {
  padding-top: 0em !important;
}
.padding-t-1 {
  padding-top: 1em !important;
}
.padding-t-2 {
  padding-top: 2em !important;
}
.padding-t-3 {
  padding-top: 3em !important;
}
.padding-t-4 {
  padding-top: 4em !important;
}
.padding-t-5 {
  padding-top: 5em !important;
}
.padding-l-0 {
  padding-left: 0em !important;
}
.padding-l-1 {
  padding-left: 1em !important;
}
.padding-l-2 {
  padding-left: 2em !important;
}
.padding-l-3 {
  padding-left: 3em !important;
}
.padding-l-4 {
  padding-left: 4em !important;
}
.padding-l-5 {
  padding-left: 5em !important;
}
.padding-r-0 {
  padding-right: 0em !important;
}
.padding-r-1 {
  padding-right: 1em !important;
}
.padding-r-2 {
  padding-right: 2em !important;
}
.padding-r-3 {
  padding-right: 3em !important;
}
.padding-r-4 {
  padding-right: 4em !important;
}
.padding-r-5 {
  padding-right: 5em !important;
}
.padding-h-0 {
  padding-left: 0em !important;
  padding-right: 0em !important;
}
.padding-h-1 {
  padding-left: 1em !important;
  padding-right: 1em !important;
}
.padding-h-2 {
  padding-left: 2em !important;
  padding-right: 2em !important;
}
.padding-h-3 {
  padding-left: 3em !important;
  padding-right: 3em !important;
}
.padding-h-4 {
  padding-left: 4em !important;
  padding-right: 4em !important;
}
.padding-h-5 {
  padding-left: 5em !important;
  padding-right: 5em !important;
}
.padding-v-0 {
  padding-top: 0em !important;
  padding-bottom: 0em !important;
}
.padding-v-1 {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}
.padding-v-2 {
  padding-top: 2em !important;
  padding-bottom: 2em !important;
}
.padding-v-3 {
  padding-top: 3em !important;
  padding-bottom: 3em !important;
}
.padding-v-4 {
  padding-top: 4em !important;
  padding-bottom: 4em !important;
}
.padding-v-5 {
  padding-top: 5em !important;
  padding-bottom: 5em !important;
}

.margin-0 {
  margin: 0em !important;
}
.margin-1 {
  margin: 1em !important;
}
.margin-2 {
  margin: 2em !important;
}
.margin-3 {
  margin: 3em !important;
}
.margin-4 {
  margin: 4em !important;
}
.margin-5 {
  margin: 5em !important;
}
.margin-b-0 {
  margin-bottom: 0em !important;
}
.margin-b-1 {
  margin-bottom: 1em !important;
}
.margin-b-2 {
  margin-bottom: 2em !important;
}
.margin-b-3 {
  margin-bottom: 3em !important;
}
.margin-b-4 {
  margin-bottom: 4em !important;
}
.margin-b-5 {
  margin-bottom: 5em !important;
}
.margin-t-0 {
  margin-top: 0em !important;
}
.margin-t-1 {
  margin-top: 1em !important;
}
.margin-t-2 {
  margin-top: 2em !important;
}
.margin-t-3 {
  margin-top: 3em !important;
}
.margin-t-4 {
  margin-top: 4em !important;
}
.margin-t-5 {
  margin-top: 5em !important;
}
.margin-l-0 {
  margin-left: 0em !important;
}
.margin-l-1 {
  margin-left: 1em !important;
}
.margin-l-2 {
  margin-left: 2em !important;
}
.margin-l-3 {
  margin-left: 3em !important;
}
.margin-l-4 {
  margin-left: 4em !important;
}
.margin-l-5 {
  margin-left: 5em !important;
}
.margin-r-0 {
  margin-right: 0em !important;
}
.margin-r-1 {
  margin-right: 1em !important;
}
.margin-r-2 {
  margin-right: 2em !important;
}
.margin-r-3 {
  margin-right: 3em !important;
}
.margin-r-4 {
  margin-right: 4em !important;
}
.margin-r-5 {
  margin-right: 5em !important;
}
.margin-h-0 {
  margin-left: 0em !important;
  margin-right: 0em !important;
}
.margin-h-1 {
  margin-left: 1em !important;
  margin-right: 1em !important;
}
.margin-h-2 {
  margin-left: 2em !important;
  margin-right: 2em !important;
}
.margin-h-3 {
  margin-left: 3em !important;
  margin-right: 3em !important;
}
.margin-h-4 {
  margin-left: 4em !important;
  margin-right: 4em !important;
}
.margin-h-5 {
  margin-left: 5em !important;
  margin-right: 5em !important;
}
.margin-v-0 {
  margin-top: 0em !important;
  margin-bottom: 0em !important;
}
.margin-v-1 {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}
.margin-v-2 {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}
.margin-v-3 {
  margin-top: 3em !important;
  margin-bottom: 3em !important;
}
.margin-v-4 {
  margin-top: 4em !important;
  margin-bottom: 4em !important;
}
.margin-v-5 {
  margin-top: 5em !important;
  margin-bottom: 5em !important;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--border);
  margin: 44px 0;
}

blockquote {
  margin: 16px 0;
  border-radius: 3px;
  padding: 1rem;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5;
  color: #22262a;
}
blockquote.success {
  border-left: 5px solid #0ac266;
  background-color: #effbf5;
}
blockquote.success b {
  font-weight: 600;
  color: var(--bg-pr);
}
blockquote.success strong {
  font-weight: 500;
  color: #09aa59;
}

.text-center {
  width: 100%;
  text-align: center;
}

.title {
  width: 100%;
  font-size: 24px;
  line-height: 1.25;
  font-family: var(--font-family-pr);
  color: #002162;
  font-weight: 700;
  margin-bottom: 25px;
  text-align: left !important;
  position: relative;
}
.title::after {
  content: "";
  width: 85px;
  background-color: var(--active);
  font-weight: 600;
  height: 5px;
  display: block;
  margin: 10px 0 25px;
}

.subtitle {
  font-size: 16px;
  color: var(--active);
  letter-spacing: 1.5px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  margin: 35px 0 20px;
}

strong {
  color: var(--bg-pr);
}

li {
  list-style-type: disc;
  font-size: 20px;
  color: var(--active);
}
li p {
  margin-bottom: 8px;
  padding: 0;
}

.content-video {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
}
.content-video .content-embed-video {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  border: none;
}

p {
  color: var(--text);
  font-size: 17px;
  line-height: 27px;
  margin-bottom: 8px;
}

.loading-full-screen {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}