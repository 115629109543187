/*Roboto*/
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./Roboto/Roboto-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(./Roboto/Roboto-Italic.ttf) format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(./Roboto/Roboto-Black.ttf) format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url(./Roboto/Roboto-BlackItalic.ttf) format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(./Roboto/Roboto-Bold.ttf) format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(./Roboto/Roboto-BoldItalic.ttf) format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(./Roboto/Roboto-Light.ttf) format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(./Roboto/Roboto-LightItalic.ttf) format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(./Roboto/Roboto-Medium.ttf) format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(./Roboto/Roboto-MediumItalic.ttf) format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(./Roboto/Roboto-Thin.ttf) format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url(./Roboto/Roboto-ThinItalic.ttf) format("truetype");
}
/*Roboto*/
/*Open Sans*/
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(./OpenSans/OpenSans-Light.ttf);
}
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(./OpenSans/OpenSans-LightItalic.ttf);
}
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./OpenSans/OpenSans-Regular.ttf);
}
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(./OpenSans/OpenSans-Regular.ttf);
}
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(./OpenSans/OpenSans-SemiBold.ttf);
}
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(./OpenSans/OpenSans-SemiBoldItalic.ttf);
}
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(./OpenSans/OpenSans-Bold.ttf);
}
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(./OpenSans/OpenSans-BoldItalic.ttf);
}
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(./OpenSans/OpenSans-ExtraBold.ttf);
}
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(./OpenSans/OpenSans-ExtraBoldItalic.ttf);
}
/*Open Sans*/
