:root,
.main {
    --font-family-pr: "Roboto", sans-serif;
    --font-family-se: "Open Sans", sans-serif;
    --shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    --overlay: rgb(0, 33, 98, 0.7);
    --border: #eeeeee;
    --transition: 350ms;

    --bg-main: #ffffff;
    --ct-main: #525252;

    --bg-back: #eff4f8;
    --ct-back: #002162;

    --bg-pr: #002162;
    --ct-pr: #ffffff;

    --title: #002162;
    --subtitle: #878c92;
    --text: #525252;
    --active: #0058c0;

    --success: ;
    --danger: ;
    --warning: ;

    --bg-disabled: #dddddd;
    --ct-disabled: #999999;
}
